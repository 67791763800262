<template>
<div>

 <b-tabs
    vertical
    end
    nav-wrapper-class="nav-vertical"
  >
      <div v-for="room in rooms" :key="room.id">
        <b-tab
          active
          :title="`${room.room_category} - ${getCurrentAccommodation(room.id)[0].title}`"
        >
          <b-row>
            <b-col md="3">
              <b-carousel
                img-width="200"
                img-height="200"
                controls
                indicators
              >
                <b-carousel-slide :img-src="getCurrentAccommodation(room.id)[0].images">
                </b-carousel-slide>
              </b-carousel>
            </b-col>
            <b-col md="4">
              <h4 class="mb-25">
                {{room.room_category}} - {{getCurrentAccommodation(room.id)[0].title}}
              </h4>
              <small class="mb-0">
                <!-- 17 - 20 Septembre -->
              </small>
              <div class="mt-2">
                <p v-if=room.description >{{room.description}}</p>
                <p v-else ><i>Aucune description</i></p>

              </div>
            </b-col>
            
          </b-row>
        </b-tab>
      </div>
 </b-tabs>
  
</div>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardBody, BCardText, BButton,BButtonGroup,BCollapse,VBToggle, BIconFileEarmarkEasel,BCarousel,BCarouselSlide } from 'bootstrap-vue'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BCollapse,
    BButtonGroup,
    BCarousel,
    BCarouselSlide
  },
  props: {
    currentAccommodations: Array,
    rooms: Array
  },
  data() {
    return {
      hasOneOption: true,
      testDate: Date.now(),
      currentGroup: {},
      image: require('@/assets/images/hotels/rooms/hotel_1.jpg'),


    }
  },
  directives: {
    'b-toggle': VBToggle,
  },
  methods: {
    getCurrentAccommodation(id) {
      let currentAccommodation = this.currentAccommodations.filter(accommodation => {
        return accommodation.rooms.find(room => room.id === id);
      })
      return currentAccommodation;
    },
  }
}
</script>

<style>
  .text-align-left {
    text-align: left;
  }

  .text-align-right {
    text-align: right;
  }

  .text-white {
    color:white;
  }


</style>
