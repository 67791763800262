<template>
  <b-card
    border-variant="primary"

  >
    <h4>{{direction}} - <span class="text-primary">{{formatDate(flightsInfo[0].departure_date, "DDDD")}}</span></h4>
    <div v-for="flight,index in flightsInfo" :key="index">
      <b-row class="text-align-left">
        <b-col md="2">
          <div class="airline-logo">
            <img :src="getAirlineInfo(flight.operator, 'logo')" alt="..." class="img-thumbnail">
          </div>
        </b-col>
        <b-col md="9">
          <b-row>
            <b-col md="1" class="airline-logo">
              <img :src="require('@/assets/images/group/flight-dot.svg')" alt="..." class="">
            </b-col>
            <b-col md="2" class="flight-time-summary">

              <span>{{formatFlightTimeInAbsolute(flight.departure_date, "hh:mm")}}</span>
            </b-col>
            <b-col md="7" class="truncate">
              <span><b>{{flight.departure_station}}</b><small> - {{getAirportDetails(flight.departure_station, 'name')}} </small> </span>
            </b-col>
          </b-row>
          <b-row style="height: 10px;">
            <b-col md="1" class="airline-logo">
              <img :src="require('@/assets/images/group/line.svg')" alt="..." class="">
            </b-col>
          </b-row>
          <b-row >
            <b-col md="1">
              <img :src="require('@/assets/images/group/plane.svg')" alt="..." class="">
            </b-col>
            <b-col md="2" class="flight-time-summary">
              <!-- <span class="text-muted">{{timeDifference(flight.departure_date, flight.arrival_date_time)}}</span> TODO: get that from BE-->
            </b-col>
            <b-col md="7" class="truncate">
              <span class="text-muted">{{flight.operator}} - {{flight.transport_number}}</span>
            </b-col>
          </b-row>
          <b-row style="height: 10px;">
            <b-col md="1" class="airline-logo">
              <img :src="require('@/assets/images/group/line.svg')" alt="..." class="">
            </b-col>
          </b-row>
          <b-row>
            <b-col md="1">
              <img :src="require('@/assets/images/group/flight-dot.svg')" alt="..." class="">
            </b-col>
            <b-col md="2" class="flight-time-summary">
              <span>{{formatFlightTimeInAbsolute(flight.arrival_date, "hh:mm")}}</span>
            </b-col>
            <b-col md="7" class="truncate">
              <span><b>{{flight.arrival_station}}</b><small> - {{getAirportDetails(flight.arrival_station, 'name')}} </small> </span>
            </b-col>
          </b-row>
        </b-col>

      </b-row>

      <div v-if="flightsInfo[index+1]">
      <b-row class="text-align-left mt-1 mb-1">
        <b-col md="2">
        </b-col>
        <b-col md="9">
          <div class="text-primary">
            <i>Escale de {{timeDifference(flight.arrival_date, flightsInfo[index+1].departure_date )}} à {{getAirportDetails(flight.arrival_station, 'city')}} ({{flight.arrival_station}})</i>
          </div>
        </b-col>
      </b-row>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardBody, BCardText, BButton,BButtonGroup,BCollapse,VBToggle, BIconFileEarmarkEasel } from 'bootstrap-vue'
import transportConfig from '@core/data/transportConfig.js'
import {useTravelInformationApi} from "@/modules/group/composables/use-travel-information-api";
import {computed,onMounted, reactive, watch, ref, forceUpdate} from "@vue/composition-api";
import {useApplicationContext} from "@/shared/composables/use-application-context";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BCollapse,
    BButtonGroup
  },
  props: {
    flightsInfo: Array,
    direction: String
  },
  data() {
    return {
      hasOneOption: true,
      testDate: Date.now(),
      currentGroup: {},
      ac_image: require('@/assets/images/group/ac.png'),
      dot_logo: require('@/assets/images/group/flight-dot.svg')
    }
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup(props, context) {
    const {getAirlineInformationMatchingOperator, getAirportsMatchingIataCode} = useTravelInformationApi();
    const {forceUpdate} = useApplicationContext();

    let airportsDetails = reactive({});
    onMounted(() => {
      props.flightsInfo.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        let flightA = new Date(a.departure_date);
        let flightB = new Date(b.departure_date);

        return flightA.getTime()-flightB.getTime();
      });

      const route = context.root.$route;
      const publicAccessToken = route.query.token;

      props.flightsInfo.forEach(async (flight) => {
          let fromIATA = flight.departure_station;
          let toIATA = flight.arrival_station;

          let fromAirportDetails = await getAirportsMatchingIataCode(fromIATA, publicAccessToken)
          let toAirportDetails = await getAirportsMatchingIataCode(toIATA, publicAccessToken)

          let objectData = {}
          objectData[fromIATA] = fromAirportDetails[0];
          objectData[toIATA] = toAirportDetails[0];

          Object.assign(airportsDetails, objectData)
          forceUpdate();
      })
    });

    const getAirportDetails = (iata, property) => {
      try {
        if (airportsDetails.hasOwnProperty(iata)) {
          return airportsDetails[iata][property]
        }
      } catch (e) {
        console.error(e, iata)
      }
    }


    return {
      airportsDetails,
      getAirportDetails
    }

  },
  methods: {
    getAirlineInfo(operator, type) {
      try {
        return transportConfig.AIRLINES[operator][type]
      } catch (err) {
        // Should be a default image
        return require('@/assets/images/group/flight-dot.svg')
      }
    },
    formatMainFlightDate(flights, desiredFormat) {
      try {

      return this.$luxon(date, {
          input: { format: "yyyy-MM-dd HH:mm", zone: "local" },
          output: {format: desiredFormat, locale: 'fr',}
      })} catch {
        return date
      }
    },
    formatDate(date, desiredFormat) {
      try {
      return this.$luxon(date, {
        input: { zone: "local" },
          output: {format: desiredFormat, locale: 'fr'}
      })} catch {
        return date
      }
    },
    formatFlightTimeInAbsolute(date, desiredFormat) {
      try {
        //Super hacky but I dont get why we sometime have timezone

        let subparts = date.split('T');

        let time = subparts[1]

        if(time.includes('+')) {
          let timepart = time.split('+')
          return timepart[0].replace("Z","")
        } else if(time.includes('-')) {
          let timepart = time.split('-')
          return timepart[0].replace("Z","")
        } else {
          return time.replace("Z","")

        }
      } catch {
        return date
      }
    },
    timeDifference(departure_time, arrival_time) {
      const departure_date_time = new Date(departure_time);
      const arrival_date_time = new Date(arrival_time);

      const diffTime = Math.abs(arrival_date_time - departure_date_time);
      const flightDuration= (diffTime / (1000 * 60))

      let hours = Math.floor(flightDuration/60)
      let minutes = (flightDuration % 60)

      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      let formatedString = `${hours}h${minutes}`

      return formatedString
    }

  }
}
</script>

<style>
  .text-align-left {
    text-align: left;
  }

  .text-align-right {
    text-align: right;
  }

  .text-white {
    color:white;
  }

  .flight-info {
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
  }

  .airport-code {
    font-weight: 500;
    font-size: 24px;
    line-height: 1;
  }

  .flight-time {
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
  }

  .airport-city, .flight-date {
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    margin-top: 2px
  }

  .small-top-padding {
    padding: 0;
    padding-top:10px;
  }

  .airline-logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

  .flight-time-summary{
    min-width: 5em;
  }

</style>
