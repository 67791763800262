import axios from '@axios'

export class GroupTransportationService {

  constructor() {}

  async addTransportOption(groupId, transportationOption) {

    return new Promise((resolve, reject) => {

      let body = {
        name :  transportationOption.name,
        departure_city : null,
        tour_operator: transportationOption.tourOperator,
        seat_selection : transportationOption.seatSelection,
        seat_release_date : transportationOption.seatReleaseDate,
        seats : transportationOption.seats,
        luggage : null,
        extra_information : null,
      }

      axios
      .post(`/groups/${groupId}/transportations`, body)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
    })
  }

  async addFlightToTransportOption(groupId, optionId, direction, flightSegment) {
    return new Promise((resolve, reject) => {

      if (direction == 'departures') {
        direction = 'DEPARTURE'
      } else if (direction == 'returns') {
        direction = 'RETURN'
      }

      flightSegment.direction = direction;

      let body = this.convertFlightToFlightModel(flightSegment)

      axios
      .post(`/groups/${groupId}/transportations/${optionId}/segments`, body)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
    })
  }

  async editTransportOption(groupId, optionId, transportationOption) {

    return new Promise((resolve, reject) => {

      let body = {
        name :  transportationOption.name,
        departure_city : null,
        tour_operator: transportationOption.tourOperator,
        seat_selection : transportationOption.seatSelection,
        seat_release_date : transportationOption.seatReleaseDate,
        seats : transportationOption.seats,
        luggage : null,
        extra_information : null,
      }

      axios
      .patch(`/groups/${groupId}/transportations/${optionId}`, body).then(response => {
        //Update
        resolve(response)
      })
      .catch(error => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
    })
  }

  async deleteTransportOption(groupId, optionId, transportationOption) {

    return new Promise((resolve, reject) => {

      axios
      .delete(`/groups/${groupId}/transportations/${optionId}`).then(response => {
        //Update
        resolve(response)
      })
      .catch(error => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
    })
  }

  async editFlightSegment(groupId, transportName, segmentId, flightSegment) {

    return new Promise((resolve, reject) => {

      let body = this.convertFlightToFlightModel(flightSegment)
      delete body.type;
      axios
      .put(`/groups/${groupId}/transportations/${transportName}/segments/${segmentId}`, body).then(response => {
        //Update
        resolve(response)
      })
      .catch(error => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
    })
  }

  async deleteFlightSegment(groupId, transportId, segmentId) {

    return new Promise((resolve, reject) => {

      axios
      .delete(`/groups/${groupId}/transportations/${transportId}/segments/${segmentId}`).then(response => {
        //Update
        resolve(response)
      })
      .catch(error => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
    })
  }

  async getTransporation(groupId, transportName, publicAccessToken) {
    let url = `/groups/${groupId}/transportations/${transportName}`;

    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`
    }

    return new Promise((resolve, reject) => {
      axios
        .get(url).then(response => {
          resolve(response)
        })
        .catch(error => {
          if (error.response) {
            reject(error.response)
          } else {
            reject(error)
          }
        })
    })
  }

  convertFlightToFlightModel(flightSegment) {
    const FLIGHT = "FLIGHT";

    //2022-12-12 08:08
    let departure_datetime = new Date(Date.UTC(flightSegment.departureDate.substring(0, 4), flightSegment.departureDate.substring(5, 7), flightSegment.departureDate.substring(8, 10), flightSegment.departureDate.substring(11, 13), flightSegment.departureDate.substring(14, 16), 0))
    let arrival_datetime = new Date(Date.UTC(flightSegment.arrivalDate.substring(0, 4), flightSegment.arrivalDate.substring(5, 7), flightSegment.arrivalDate.substring(8, 10), flightSegment.arrivalDate.substring(11, 13), flightSegment.arrivalDate.substring(14, 16), 0))

    let flightModel = {
      direction: flightSegment.direction,
      type: FLIGHT,
      operator: flightSegment.operator,
      departure_date: new Date(flightSegment.departureDate+"z"),
      arrival_date: new Date(flightSegment.arrivalDate+"z"),
      transport_number:  flightSegment.transportNumber,
      departure_city: flightSegment.departureStation,
      departure_station: flightSegment.departureStation,
      arrival_city: flightSegment.arrivalStation,
      arrival_station: flightSegment.arrivalStation
    }

    return flightModel;

  }

}
